.listWrapper ul {
  list-style-type: disc;
}

.listWrapper ul li ul {
  list-style-type: circle;
}

.listWrapper ul li ul li ul {
  list-style-type: square;
}

.listWrapper ul li ul li ul li ul {
  list-style-type: disc;
}

.listWrapper ul li ul li ul li ul li ul {
  list-style-type: circle;
}

.listWrapper ul li ul li ul li ul li ul li ul {
  list-style-type: square;
}

.listWrapper ol {
  list-style-type: decimal;
}

.listWrapper ol li ol {
  list-style-type: lower-alpha;
}

.listWrapper ol li ol li ol {
  list-style-type: lower-roman;
}

.listWrapper ol li ol li ol li ol {
  list-style-type: lower-greek;
}

.listWrapper ol li ol li ol li ol li ol {
  list-style-type: decimal;
}

.listWrapper ol li ol li ol li ol li ol li ol {
  list-style-type: lower-alpha;
}
